import { createRouter, createWebHashHistory } from 'vue-router'
import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/',
    redirect: '/signin',
  },
  {
    path: '/signin',
    name: 'signin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start" */ '@/views/home/Home.vue'),
  },
  {
    path: '/help/:id',
    name: 'help',
    component: () => import(/* webpackChunkName: "start" */ '@/views/help/Help.vue'),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "start" */ '@/views/support/Support.vue'),
  },
  {
    path: '/accept/account/:invitationId',
    name: 'acceptAccount',
    redirect: to => {
      to.params.signout = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/accept/user/:invitationId',
    name: 'acceptUser',
    redirect: to => {
      to.params.signout = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/accept/code/:oc',
    name: 'acceptCode',
    redirect: to => {
      to.params.signout = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/activate/lf',
    name: 'lfCode',
    redirect: to => {
      to.params.signout = true;
      to.params.scPrompt = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/activate/lf/:sc',
    name: 'lfAddDevice',
    redirect: to => {
      to.params.signout = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    redirect: to => {
      return { name: 'signin', query: to.query };
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    redirect: to => {
      if (to.query.locale) {
        to.params.locale = to.query.locale;
      }
      if (to.query.target) {
        to.params.target = window.encodeURIComponent(to.query.target);
      }
      if (to.query.orgId) {
        to.params.orgId = to.query.orgId;
      }
      to.params.redirect = true;
      return { name: 'signin', query: to.params };
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
