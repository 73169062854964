import { defineStore } from 'pinia'

export const useL10NStore = defineStore('l10n', {
  state: () => (
    {
      i18n: null
    }),
  getters: {
  },
  actions: {
    init(i18n) {
      this.i18n = i18n;
    },
  },
});
