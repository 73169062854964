import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n, { initI18n } from '@/i18n'
import { createPinia } from 'pinia'
import { useL10NStore } from '@/stores/i18n.store'

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

import './App.scss';

// Synchronization mechanism
// 1. Load header so we can freely use PrintOS Header API
// 2. Do any global application initialization that should be done before rendering app (like localization)
printOSHeaderReady().then(() => {
  initApp().then(() => {
    app.use(router);
    app.use(i18n);
    useL10NStore().init(i18n);
    app.mount('#app');
  });
});

function printOSHeaderReady() {
  return new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      reject();
    }, 180*1000);

    if (window.$printos?.readyState === 'complete') {
      clearTimeout(timeout);
      resolve(window.$printos);
    }
    else {
      document.addEventListener('posb-ready', () => {
        clearTimeout(timeout);
        resolve(window.$printos);
      });
    }
  });
}

function initApp() {
  let promises = [];
  promises.push(initI18n());
  return Promise.all(promises);
}
